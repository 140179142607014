if (document.querySelector('#leadership')) {
  let triggers = document.querySelectorAll('#leadership .leader');
  let modals = document.querySelectorAll('#leadership-modals .modal');
  let modalBG = document.querySelector('#leadership-modals');

  modalController(triggers, modals, modalBG);
}

function modalController(triggers, modals, modalBG) {
  triggers.forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
      e.stopPropagation();

      let num = trigger.getAttribute('data-modal-btn');
      let modal = modals[num - 1];

      document.body.classList.add('freeze');
      modalBG.classList.add('freeze');
      modal.classList.add('active');
    });
  });

  modals.forEach((modal) => {
    modal.querySelector('.close').addEventListener('click', () => {
      document.body.classList.remove('freeze');
      modalBG.classList.remove('freeze');
      modal.classList.remove('active');
    });
  });

  document.querySelector('#leadership-modals:not(.modal)').addEventListener('click', (e) => {
    if(e.target !== e.currentTarget) return;

    document.body.classList.remove('freeze');
    modalBG.classList.remove('freeze');

    modals.forEach((modal) => {
      modal.classList.remove('active');
    });
  });
}