// if (document.querySelector("#tabbed")) {
//   let hash = window.location.hash;
//   let query = window.location.search;
//   const urlParams = new URLSearchParams(query);

//   let tab =
//     hash === ""
//       ? 1
//       : document
//           .querySelector(`#${urlParams.get("tab")}`)
//           .getAttribute("data-tab");

//   toggleTabs(tab);

//   document.querySelectorAll("#tabs .item").forEach((btn) => {
//     btn.addEventListener("click", function (e) {
//       e.preventDefault();

//       // Pushing the appropriate state to the browser's history
//       // history.pushState({}, "", `#${btn.id}`);

//       document.body.scrollTop = 0;
//       document.documentElement.scrollTop = 0;

//       toggleTabs(btn.getAttribute("data-tab"));
//     });
//   });

//   window.addEventListener(
//     "hashchange",
//     () => {
//       const tab = document
//         .querySelector(window.location.hash)
//         .getAttribute("data-tab");
//       toggleTabs(tab);
//     },
//     false
//   );
// }

// function toggleTabs(tabNum) {
//   let btn = document.querySelector(`[data-tab="${tabNum}"]`);

//   // Removing all tabBtns active states
//   document
//     .querySelectorAll("#tabs .item")
//     .forEach((obj) => obj.classList.remove("active"));

//   // Activating the clicked btn
//   btn.classList.add("active");

//   // Activating the appropriate tab
//   document.querySelectorAll(".tab").forEach((tab) => {
//     if (tab.getAttribute("data-tab-content") == tabNum) {
//       tab.classList.add("active");
//     } else {
//       tab.classList.remove("active");
//     }
//   });
// }
