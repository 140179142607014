import CustomSelect from 'vanilla-js-dropdown';

let careerFilters = document.querySelector('#careers .filters');

function getUrlVars() {
  var vars = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });

  return vars;
}

if (careerFilters) {
  careerFilters.querySelector('#city').addEventListener('change', (e) => {
    if (e.target.value !== '') {
      window.location.href = `${window.location.origin + window.location.pathname}?city=${e.target.value}`;
    }
  });

  careerFilters.querySelector('#team').addEventListener('change', (e) => {
    if (e.target.value !== '') {
      window.location.href = `${window.location.origin + window.location.pathname}?team=${e.target.value}`;
    }
  });

  careerFilters.querySelector('#type').addEventListener('change', (e) => {
    if (e.target.value !== '') {
      window.location.href = `${window.location.origin + window.location.pathname}?type=${e.target.value}`;
    }
  });
}

if (document.querySelector('#city')) {
  var filterCity = new CustomSelect({
    elem: 'city'
  });
}

if (document.querySelector('#team')) {
  var filterTeam = new CustomSelect({
    elem: 'team'
  });
}

if (document.querySelector('#type')) {
  var filterType = new CustomSelect({
    elem: 'type'
  });
}