import ScrollReveal from "scrollreveal";

let heroElements = document.querySelectorAll("#hero h1, #hero p");
let statsElements = document.querySelectorAll("#stats .card");
let cardsElements = document.querySelectorAll(".cards .card, .cards .plus");
let imageBlockElements = document.querySelectorAll(
  ".image-block .content, .image-block .image, image-block .intro"
);
let crossLinkElements = document.querySelectorAll("#cross-links .card");
let processCardsElements = document.querySelectorAll("#process-cards .card");
let processRowsElements = document.querySelectorAll(
  "#process-rows .process-row"
);

let options = {
  up: {
    delay: 500,
    interval: 300,
    origin: "bottom",
    distance: "10em",
  },
  down: {
    delay: 500,
    interval: 300,
    origin: "top",
    distance: "10em",
  },
  left: {
    delay: 500,
    interval: 300,
    origin: "left",
    distance: "10em",
  },
  right: {
    delay: 500,
    interval: 300,
    origin: "right",
    distance: "10em",
  },
};

ScrollReveal().reveal(heroElements, options.left);
ScrollReveal().reveal(statsElements, options.left);
ScrollReveal().reveal(cardsElements, options.left);
ScrollReveal().reveal(processCardsElements, options.up);
ScrollReveal().reveal(processRowsElements, options.up);
ScrollReveal().reveal(imageBlockElements, options.left);
ScrollReveal().reveal(crossLinkElements, options.up);
