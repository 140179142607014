import anime from 'animejs/lib/anime.es.js';

let mobileNav = document.querySelector('#mobile-nav');

document.querySelector('#hamburger').addEventListener('click', e => {
  e.preventDefault();

  var tl = anime.timeline({
    easing: 'easeInOutQuad',
    duration: 300
  });

  tl
  .add({
    targets: mobileNav,
    opacity: [0, 1],
    translateX: '0',
    complete: anim => {
      mobileNav.classList.add('open');
    }
  })
  .add({
    targets: document.querySelectorAll('#mobile-nav .item'),
    translateX: ['100vw', '0'],
    delay: anime.stagger(50),
    loop: true
  });
});

document.querySelector('#close').addEventListener('click', e => {
  e.preventDefault();

  var tl = anime.timeline({
    easing: 'easeInOutQuad',
    duration: 150
  });

  tl
  .add({
    targets: document.querySelectorAll('#mobile-nav .item'),
    translateX: '100vw',
    delay: anime.stagger(25)
  })
  .add({
    targets: mobileNav,
    opacity: [1, 0],
    translateX: '100%',
    complete: anim => {
      mobileNav.classList.remove('open');
    }
  });

});