import Glide from "@glidejs/glide";

if (document.querySelector(".glide")) {
  new Glide(".glide", {
    type: "carousel",
    perView: 1,
    autoplay: 5000,
    animationDuration: 500,
  }).mount();
}
